import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import './TeamMember.css';

const TeamMember = (props) => {
    const {name, title, img, about, linkedin, facebook, email} = props.member;
    return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="team">
                <div className="image">
                    <img src={img} alt={name} />
                </div>
                <div className="info">
                    <h3 className="name">{name}</h3>
                    <div className="title">{title}</div>
                    <p>{about}</p>
                    <div className="social">
                        {
                            facebook !== "" &&
                            <a href={facebook} target="blank"><FontAwesomeIcon icon={faFacebookF}/></a>
                        }
                       
                        {
                            linkedin !== "" &&
                            <a href={linkedin} target="blank"><FontAwesomeIcon icon={faLinkedinIn}/></a>
                        }

                        {
                            email !== "" &&
                            <a href={email} target="blank"><FontAwesomeIcon icon={faEnvelope}/></a>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamMember;
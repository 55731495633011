import React from 'react';
import HireUsForm from './HireUsForm/HireUsForm';
import NavHireUs from './NavHireUs/NavHireUs';

const HireUs = () => {
    return (
        <>
            <NavHireUs></NavHireUs>
            <HireUsForm></HireUsForm>
        </>
    );
};

export default HireUs;
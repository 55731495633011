import React, { useRef } from 'react';
import './HireUsForm.css';
import emailjs from '@emailjs/browser'
import swal from 'sweetalert';

const Form = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('ls_gmail', 'ls_hireus_temp', form.current, 'user_Xos5W3fCRsdFy8Pl3S4Hh')
            .then((result) => {
                swal({
                    title: "Message sent!",
                    text: "Thank you for your message. One of our team members will contact you soon.",
                    icon: "success",
                });
            }, (error) => {
                swal({
                    title: "Message not sent!",
                    text: "Unexpected error occured",
                    icon: "error",
                    dangerMode: true,
                });
            });
         e.target.reset();
    };

    return (
        <div className="hireUs-top">
            <div className='py-5'>
                <div className="container">
                    <div className="row mt-5">
                        <h1 className="fw-bold text-center custom-txt-clr my-5">Let's create something great together.</h1>
                        <div className="col-md-7">
                            <form ref={form} onSubmit={sendEmail} className='mx-auto my-5 p-5 card hireUsForm'>
                                <div className="row">
                                    <div className="mb-4 col-12">
                                        <h2 className="fw-bold custom-txt-clr">Get A Quote</h2>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="name">Full Name<span className="custom-txt-clr"> *</span></label>
                                        <input type="text" name="name" id="name" className="form-control mb-4" required="required"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="email">Work Email<span className="custom-txt-clr"> *</span></label>
                                        <input type="email" name="email" id="email" className="form-control mb-4" required="required"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="telephone">Phone Number</label>
                                        <input type="tel" name="telephone" id="telephone" className="form-control mb-4"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="website">Company Website</label>
                                        <input type="url" name="website" id="website" className="form-control mb-4"/>
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="description">Description Of The Task<span className="custom-txt-clr"> *</span></label>
                                        <textarea type="text" name="description" id="description" className="form-control mb-4" rows="7" required="required"/>
                                    </div>
                                    <div>
                                        <button type="submit" className="custom-btn">Submit Request</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-5 my-5 text-center">
                            <div>
                                <h3 className="custom-txt-clr fw-bold text-center">Why Us?</h3>
                                <p>We can help you to turn your ideas into reality. We have experienced team members who will understand what your need and according to that they will make a plan to work on your project. 100% client satisfation is a guarentee.</p>
                            </div>
                            <div className="mt-5">
                                <p>
                                    <strong>Siams Corporation</strong><br />
                                    Pabna, Rajshahi
                                </p>
                                <p>
                                    <span className="phone">+880 17 6634 2025</span><br />
                                    <a href="mailto:levelupservicebd@gmail.com" className="text-primary">levelupservicebd@gmail.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="text-secondary text-center fst-italic my-5">
                        <h3 className="fw-bold">" We are not immortal to live forever.</h3>
                        <h3 className="fw-bold">So, let's make our deeds great together. "</h3>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Form;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Service.css'

const Service = (props) => {
    const {title, description, icon} = props.singleService;
    // change icon type from here
    const iconType = "free-solid-svg-icons";
    return (
        <div className="col-lg-4 col-md-6">
            <div className="service">
                <div className="icon">
                    <FontAwesomeIcon icon={require(`@fortawesome/${iconType}/`)[icon]} />
                </div>
                <div className="info">
                    <h4 className="title">{title}</h4>
                    <p className="desc">{description}</p>
                </div>
            </div>
        </div>
    );
};

export default Service;
import React, { useEffect, useState } from "react";
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "animate.css";
import $ from 'jquery';
import "./Nav.css";
import { useRouteMatch } from "react-router-dom";
const Nav = () => {
  const isBlogRoute = useRouteMatch("/Blog");

  const sections = document.querySelectorAll("section");
  const navAnchor = document.querySelectorAll("nav .container ul li a");

  if(!isBlogRoute){
    window.addEventListener("scroll", () => {
      let current = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (window.pageYOffset >= sectionTop - sectionHeight / 3) {
          current = section.getAttribute("id");
        }
      });
      
      navAnchor.forEach((a) => {
        a.classList.remove("active");
        if (a.classList.contains(current)) {
          a.classList.add("active");
        }
      });
    });
  }
   
  const [showToast, hideToast] = useState(true);
  
  useEffect(() => {
    const notify = () => toast.info('A new interface is coming soon. Stay tuned!', {
      toastId: 'info1',
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      pauseOnFocusLoss: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false
    });
  

  notify();
}, [])

  $(document).on('click', '.nav-link', function(){
    $('.nav-link').removeClass('active');
    $(this).addClass('active')
  })

  const [navbar, setNavbar] = useState(false);
    const navScrollEffect = () =>{
      if(window.scrollY >= 80){
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    }
  window.addEventListener('scroll', navScrollEffect);

  return (
    <>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      rtl={false}
      transition={Slide}
      pauseOnFocusLoss
      pauseOnHover
      /><ToastContainer />
      <nav className={navbar ? 'navbar scrollNavEffect header navbar-expand-lg navbar-dark fixed-top' : 'navbar header navbar-expand-lg navbar-dark fixed-top'}>
        <div className="container">
          <a className="navbar-brand" href="/Home">
          <svg width="110" height="26" viewBox="0 0 131 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.2501 0.0144318V7.43957H9.01754V9.29465H25.2501V26H0V18.5749H16.2326V16.7198H0V0.0144318H25.2501Z" fill="#FF3366"/>
            <path d="M36.8082 26H27.7907V0.0144318H36.8082V26Z" fill="#FF3366"/>
            <path d="M39.3486 0.0144318H66.4013V26H57.3838V20.4299L51.9714 26H39.3627L39.3486 0.0144318ZM48.3849 7.43957V18.5749L57.4025 9.29465V7.43957H48.3849Z" fill="#FF3366"/>
            <path d="M94.1917 0.0144208H103.209V26H94.1917V11.1497L90.5819 14.8647V26H81.569V11.1497L77.9591 14.8647V26H68.9322V0.0144208H77.9498V3.71497L81.5597 0H90.5725V3.71497L94.1917 0.0144208Z" fill="#FF3366"/>
            <path d="M131 0.0144318V7.43957H114.767V9.29465H131V26H105.75V18.5749H121.982V16.7198H105.75V0.0144318H131Z" fill="#FF3366"/>
          </svg>
          </a>
          <button
            className="navbar-toggler order-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="nav navbar-nav ms-auto item_style text-end">
              <li className="nav-item">
                <a className={`nav-link home-section ${(!isBlogRoute?.isExact) && 'active'} `} href="/#home">
                  <span>HOME</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link about-section" href="/#about">
                  <span>ABOUT</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link team-section" href="/#team">
                  <span>TEAM</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link services-section" href="/#services">
                  <span>SERVICES</span>
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link work-section" href="#work">
                  <span>WORK</span>
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link clients-section" href="/#clients">
                  <span>CLIENTS</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link contact-section" href="/#contact">
                  <span>CONTACT</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/Blog" className={`nav-link blog-section ${(isBlogRoute?.isExact) && 'active'} `}>
                  <span>BLOG</span>
                </a>
              </li>
            </ul>
          </div>
          {/* <a href="/HireUs">
            <button type="button" className="hireUsBtn order-1">REQUEST A QUOTE</button>
          </a> */}
        </div>
      </nav>
    </>
  );
};

export default Nav;

import React, { useEffect, useState } from 'react';
import Service from '../Service/Service';

const Services = () => {
    const [myServices, setService] = useState([]);
    useEffect( ()=>{
        fetch('./ourServices.json')
        .then(res=> res.json())
        .then(data=> setService(data))
    }, []);

    return (
        <section id="services-section">
            <div id="services" className="content">
                <div className="container pt-4">
                    <h2 className="content-title">Our Services</h2>
                    <p className="content-desc">
                        Siams Corporation is a multi-service provider company with highly qualified team that designs and creates innovative, effective websites and applications that capture your brand, improve your conversion rates, and maximize your revenue to help grow your business and achieve your goals.
                    </p>
                    <div className="row">
                        {
                            myServices.map( singleService =>
                                <Service key={singleService.id} singleService={singleService}></Service>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
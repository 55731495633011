import React from 'react';
import Contact from './Contact/Contact';
import Clients from './Clients/Clients';
// import Works from './Works/Works';
import Services from './Services/Services';
import Team from './Team/Team';
import About from './About/About';
import Header from './Header/Header';
import Nav from './Nav/Nav';

const Home = () => {
    return (
        <>
            <Nav></Nav>
            <Header></Header>
            <About></About>
            <Team></Team>
            <Services></Services>
            {/* <Works></Works> */}
            <Clients></Clients>
            <Contact></Contact>
        </>
    );
};

export default Home;
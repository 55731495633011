import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import './Team.css';
import TeamMember from '../TeamMember/TeamMember';

const Team = () => {
    const [teamMember, setTeamMember] = useState([]);
    useEffect( ()=>{
        fetch('./teamMembers.json')
        .then(res=> res.json())
        .then(data=> setTeamMember(data))
    }, []);

    return (
        <section id="team-section">
            <div id="team" className="content">
                <div className="container pt-4">
                    <h2 className="content-title">Our Leadership</h2>
                    <p className="content-desc">
                        At Siams Corporation, we have the experience and skills to deliver highly functional and artfully designed graphic designs, websites &amp; marketing services. <br/> We may be a small team but we deliver big results.
                    </p>
                    <div className="row justify-content-center">
                        {
                            teamMember.map( member =>
                                <TeamMember key={member.id} member={member}></TeamMember>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="team-quote-content bg-black-darker has-bg p-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 quote text-center">
                            <FontAwesomeIcon icon={faQuoteLeft} /> Passion leads to dedication, dedication leads to performance, <br />
                            performance leads to <span className="custom-txt-clr">success</span>!  
                            <FontAwesomeIcon icon={faQuoteRight} />
                            <small>Siams Corporation, Multi-Service Provider Team in BD</small>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Team;
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser'
import swal from 'sweetalert';
import './Contact.css';

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('ls_gmail', 'ls_contact_temp', form.current, 'user_Xos5W3fCRsdFy8Pl3S4Hh')
            .then((result) => {
                swal({
                    title: "Message sent!",
                    text: "Thank you for your message. One of our team members will contact you soon.",
                    icon: "success",
                });
            }, (error) => {
                swal({
                    title: "Message not sent!",
                    text: "Unexpected error occured",
                    icon: "error",
                    dangerMode: true,
                });
            });
         e.target.reset();
    };

    return (
        <section id="contact-section">
            <div id="contact" className="content">
                <div className="container pt-4">
                    <h2 className="content-title">Contact Us</h2>
                    <p className="content-desc">
                        Our team is open for your ideas, questions and needs. Our clients get the superior results when a short-term acquaintance turns into a long-term collaboration.
                    </p>
                    <div className="row">
                        <div className="col-lg-5">
                            <h3>If you have a project you would like to discuss, get in touch with us.</h3>
                            <p>
                                Feel free to send a message to us if you have any questions. We will be happy to answer you questions and clarify everything to you. Just send a message and one of our team members will contact you as soon as possible.
                            </p>
                            <p>
                                <strong>Siams Corporation</strong><br />
                                Pabna, Rajshahi
                            </p>
                            <p>
                                <span className="phone">+880 17 6634 2025</span><br />
                                <a href="mailto:levelupservicebd@gmail.com" className="text-primary">levelupservicebd@gmail.com</a>
                            </p>
                        </div>
                        <div className="col-lg-7">
                            <form ref={form} onSubmit={sendEmail}>
                                <div className="card p-5">
                                    <h2 className="custom-txt-clr fw-bold mb-3">Contact Form</h2>
                                    <label htmlFor="user_name">Name <span className="custom-txt-clr">*</span></label>
                                    <input type="text" name="user_name" id="user_name" className="form-control mb-3" required="required"/>
                
                                    <label htmlFor="user_email">Email <span className="custom-txt-clr">*</span></label>
                                    <input type="email" name="user_email" id="user_email" className="form-control mb-3" required="required"/>

                                    <label htmlFor="subject">Subject <span className="custom-txt-clr">*</span></label>
                                    <input type="text" name="subject" id="subject" className="form-control mb-3" required="required"/>
                               
                                    <label htmlFor="message">Message <span className="custom-txt-clr">*</span></label>
                                    <textarea name="message" id="message" className="form-control mb-3" rows="7" required="required"></textarea>
                             
                                    <button type="submit" className="custom-btn">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faGithub,
    faFacebookF,
    faInstagram,
    faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";

const Footer = () => {

    const [showScroll, setShowScroll] = useState(false)
    const checkScrollTop = () => {    
        if (!showScroll && window.pageYOffset > 500){
            setShowScroll(true)    
        } else if (showScroll && window.pageYOffset <= 500){
            setShowScroll(false)    
        }  
    };

    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };
    
    const date = new Date();
    const year = date.getFullYear();

    window.addEventListener('scroll', checkScrollTop)

    window.addEventListener("load", function () {
        const loader = document.querySelector(".loader");
        loader.className += " hidden"; // class "loader hidden"
    });

    return (
        <div className="footer">
            <div className="loader">
                <div className="spinner-border custom-txt-clr" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                {/* <div className="custom-txt-clr ms-2 fs-4">
                    Loading....
                </div> */}
            </div>
            <div className="container">
                <div className="row justify-content-between footer-content">
                    <div className="footer-brand col-md-3">
                        <svg width="131" height="26" viewBox="0 0 131 26" fill="#797c83" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25.2501 0.0144318V7.43957H9.01754V9.29465H25.2501V26H0V18.5749H16.2326V16.7198H0V0.0144318H25.2501Z"/>
                            <path d="M36.8082 26H27.7907V0.0144318H36.8082V26Z" />
                            <path d="M39.3486 0.0144318H66.4013V26H57.3838V20.4299L51.9714 26H39.3627L39.3486 0.0144318ZM48.3849 7.43957V18.5749L57.4025 9.29465V7.43957H48.3849Z"/>
                            <path d="M94.1917 0.0144208H103.209V26H94.1917V11.1497L90.5819 14.8647V26H81.569V11.1497L77.9591 14.8647V26H68.9322V0.0144208H77.9498V3.71497L81.5597 0H90.5725V3.71497L94.1917 0.0144208Z"/>
                            <path d="M131 0.0144318V7.43957H114.767V9.29465H131V26H105.75V18.5749H121.982V16.7198H105.75V0.0144318H131Z"/>
                        </svg>
                    </div>
                    <div className="col-md-6 useful-links">
                        <ul>
                            <li className="col-md-3">
                                <a href="/#about">About Us</a>
                            </li>
                            <li className="col-md-3">
                                <a href="/#team">Our Team</a>
                            </li>
                            <li className="col-md-3">
                                <a href="/#contact">Contact Us</a>
                            </li>
                            <li className="col-md-3">
                                <a href="/HireUs">Get A Quote</a>
                            </li>
                        </ul>
                    </div>
                    <div className="social-icons pt-3 fs-5 col-md-3">
                        <a href="https://www.facebook.com/levelupservicebd" target="blank">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a href="https://www.instagram.com/levelupservicebd/" target="blank">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/levelupservicebd/"
                            target="blank"
                        >
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                        <a
                            href="https://www.youtube.com/@levelupservicebd"
                            target="blank"
                        >
                            <FontAwesomeIcon icon={faYoutube} />
                        </a>
                        <a href="https://github.com/levelupservicebd" target="blank">
                            <FontAwesomeIcon icon={faGithub} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="copyright-info">
                <small>
                    &copy; Copyright Siams Corporation 2020-{year}
                </small>
            </div>
            <div>
                <button type="button" className="scrollTop" onClick={scrollTop} style={{opacity: showScroll ? '1' : '0'}}>
                    <div className="top-btn-icon mx-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 37.62 49.4141"><polygon points="33.061 10.497 23.954 3.782 22.654 2.83 20.66 1.364 18.808 0 16.955 1.364 14.961 2.83 13.662 3.782 4.554 10.497 0 13.842 0 19.502 4.554 16.145 13.662 9.442 18.216 6.085 18.808 5.647 19.4 6.085 23.954 9.442 33.061 16.145 37.615 19.502 37.615 13.842 33.061 10.497" fill="#f36"/><polygon points="33.06 24.144 37.62 27.504 37.62 21.844 33.06 18.494 24.31 12.049 24.31 12.044 23.01 11.094 19.75 8.694 19.75 25.304 19.75 25.304 23.95 28.404 33.06 35.104 33.06 40.404 23.95 33.694 22.65 32.744 19.75 30.604 19.75 36.254 23.95 39.354 33.06 46.054 37.62 49.414 37.62 32.804 33.06 29.464 24.31 23.01 24.31 17.709 33.06 24.144" fill="#f36"/><polygon points="13.67 28.404 13.32 28.664 13.32 33.952 4.56 40.404 4.56 18.494 0 21.844 0 49.414 4.56 46.054 7.692 43.751 7.69 43.754 13.32 39.614 13.67 39.354 17.86 36.254 17.87 25.304 13.67 28.404" fill="#f36"/></svg>
                    </div>
                    <span className="top-btn-txt">UP</span>
                </button>
            </div>
        </div>
    );
};

export default Footer;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import "./About.css";

const About = () => {
	  const [loading] = React.useState(false);
    const containerProps = {
        'aria-busy': loading
    };

  return (
    <section id="about-section">
      <div
        id="about"
        className="content animate__animated animate__fadeInDown"
      >
        <div className="container pt-4">
          <h2 className="content-title">About Us</h2>
          <p className="content-desc">
            A company of fiery-eyed thinkers and tinkers willing to help business enthusiasts from all over the world with graphic design, web development and mobile app development. 
          </p>
          <div className="row">
            <div className="col-lg-4 mb-4">
              <div className="about">
                <h3 className="mb-3">Our Story</h3>
                <p className="text-justify">
                  We've seen what can happen when companies spend time and money in the hopes of getting a creative design or website or application, but end up trapped by unprofessional design, lack of support, hidden costs, unwanted ads and sites that don't show up on search engines.
                </p>
                <p className="text-justify">
                  Some agencies are geared towards finding endless ways in which to spend your money, without the necessary expertise or care for your needs. We realised that we were in a position to help all businesses - from start-ups and SMEs to corporate clients - with a much better deal. Then Siams Corporation was born.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <h3 className="mb-3">Our Philosophy</h3>
              <div className="about-author">
                <div className="quote">
                  <FontAwesomeIcon icon={faQuoteLeft} />
                  <h3>
                    We work harder,
                    <br />
                    <span>
                      to help our clients reach their goals faster
                    </span>
                  </h3>
                  <FontAwesomeIcon icon={faQuoteRight} />
                </div>
                <div className="author">
                  <div className="image">
                    <img src="https://i.ibb.co/3yrhMnP/siam-updated-sq-283x283-128x128.png" alt="Siam Parvez" />
                  </div>
                  <div className="info">
                    Siam Parvez
                    <small>Chief Innovation Officer</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <h3 className="mb-3">Our Experience</h3>
              <div className="skills">
                <div className="skills-name">Web Development</div>
                <div className="progress mb-3">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-theme"
                    style={{ width: "97%" }}
                  >
                    <span className="progress-number">97%</span>
                  </div>
                </div>
                <div className="skills-name">Graphic Design</div>
                <div className="progress mb-3">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-theme"
                    style={{ width: "95%" }}
                  >
                    <span className="progress-number">95%</span>
                  </div>
                </div>
                <div className="skills-name">Digital Marketing</div>
                <div className="progress mb-3">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-theme"
                    style={{ width: "92%" }}
                  >
                    <span className="progress-number">92%</span>
                  </div>
                </div>
                <div className="skills-name">App Development</div>
                <div className="progress mb-3">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-theme"
                    style={{ width: "87%" }}
                  >
                    <span className="progress-number">87%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="milestone" className="milestone-content bg-black-darker has-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 milestone-col">
              <div className="milestone">
                <div className="number">
                  <CountUp end={340} duration="0.5" redraw={true} containerProps={containerProps} suffix="+">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </div>
                <div className="title">Successful Projects</div>
              </div>
            </div>

            <div className="col-lg-4 milestone-col">
              <div className="milestone">
                <div className="number">
                  <CountUp end={130} duration="0.5" redraw={true} containerProps={containerProps} suffix="+">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </div>
                <div className="title">Happy Clients</div>
              </div>
            </div>

            <div className="col-lg-4 milestone-col">
              <div className="milestone">
                <div className="number">
                  <CountUp end={350} duration="0.5" redraw={true} containerProps={containerProps} suffix="+">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </div>
                <div className="title">Creative Designs</div>
              </div>
            </div>

            {/* <div className="col-lg-3 milestone-col">
              <div className="milestone">
                <div className="number">
                  <CountUp end={50} duration="0.5" redraw={true} containerProps={containerProps} suffix="+">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </div>
                <div className="title">Attractive Websites</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

import React from 'react';
import "./Header.css";

const Header = () => {
    return (
        <section id="home-section">
            <header className="header_style">
            {/*  begin #home */} 
                <div id="home" className="has-bg home">
                    {/*  begin container */} 
                    <div className="container home-content text-center">
                        <h1>Welcome To <span className="custom-txt-clr">Siams</span></h1>
                        <h3>Tech Based Multi-Service Provider</h3>
                        <p>
                            We provide a wide range of services<br />
                            Simply <a href="/HireUs" className="custom-link">hire us</a> to take your business to a new level.
                        </p>
                        <a href="/HireUs" className="custom-btn">Hire Us</a>
                        <a href="#contact" className="ms-3 custom-btn-outline">Contact</a>
                    </div>
                    {/*  end container */} 
                </div>
                {/*  end #home */} 
            </header>
        </section>
    );
};

export default Header;
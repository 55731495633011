import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import './Clients.css';
const Clients = () => {
    return (
        <section id="clients-section">
            <div id="clients" className="content has-bg bg-green">
                {/* begin container */}
                <div className="container pt-4">
                    <h2 className="content-title">Our Client Testimonials</h2>
                    {/* begin carousel */}
                   

                    <div id="testimonials" className="carousel slide testimonials" data-bs-ride="carousel">
                        <div className="carousel-inner text-center">
                            <div className="carousel-item active mb-5">
                                <div>
                                    <blockquote>
                                        <FontAwesomeIcon icon={faQuoteLeft} />
                                            It has been great working with Siams Corporation to get started on my project. Their team selection to meet my needs was done in a fast, smooth and effortless manner.
                                        <FontAwesomeIcon icon={faQuoteRight} />
                                    </blockquote>
                                    <div className="name">
                                        <span className="lh-0 fs-5">—</span> <span className="custom-txt-clr">Larry J. Morrow</span>
                                        <div>
                                            <div>Founder &amp; Chief Innovation Officer</div>
                                            <div className="fst-italic">A-PLUS Community Solutions, Inc.</div>
                                        </div>
                                    </div>
                               </div>
                            </div>
                            <div className="carousel-item mb-5">
                                <div>
                                    <blockquote>
                                        <FontAwesomeIcon icon={faQuoteLeft} />
                                            Siams Corporation team went above and beyond to ensure our website was the best it could be. We could not be more pleased with our website results and Siams Corporation's ongoing service. We highly recommend them.
                                        <FontAwesomeIcon icon={faQuoteRight} />
                                    </blockquote>
                                    <div className="name">
                                        <span className="lh-0 fs-5">—</span> <span className="custom-txt-clr">Ben Smith</span>
                                        <div>
                                            <div>Owner</div>
                                            <div className="fst-italic">Pretty Girl Aesthetics</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item mb-5">
                                <div>
                                    <blockquote>
                                        <FontAwesomeIcon icon={faQuoteLeft} />
                                            Siams Corporation has an amazing ability to beautifully and clearly reflect an organization's purpose. We are now in a much better position to move forward with our core mission and new projects.
                                        <FontAwesomeIcon icon={faQuoteRight} />
                                    </blockquote>
                                    <div className="name">
                                        <span className="lh-0 fs-5">—</span> <span className="custom-txt-clr">Charlene Baptiste</span>
                                        <div>
                                            <div>Founder</div>
                                            <div className="fst-italic">BaptisteCave</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item mb-5">
                                <div>
                                    <blockquote>
                                        <FontAwesomeIcon icon={faQuoteLeft} />
                                            I consider Siams Corporation as a top notch design company! They have done an amazing job with our website and our identity. Siams Corporation is always on top of our needs and they respond quickly. You just can't beat this company.
                                        <FontAwesomeIcon icon={faQuoteRight} />
                                    </blockquote>
                                    <div className="name">
                                        <span className="lh-0 fs-5">—</span> <span className="custom-txt-clr">Paul Jeboda</span>
                                        <div>
                                            <div>CEO</div>
                                            <div className="fst-italic">Viral Touch</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ol className="carousel-indicators mb-0">
                                <li type="button" data-bs-target="#testimonials" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></li>
                                <li type="button" data-bs-target="#testimonials" data-bs-slide-to="1" aria-label="Slide 2"></li>
                                <li type="button" data-bs-target="#testimonials" data-bs-slide-to="2" aria-label="Slide 3"></li>
                                <li type="button" data-bs-target="#testimonials" data-bs-slide-to="3" aria-label="Slide 4"></li>
                            </ol>
                        </div>
                    </div>
                    {/* end carousel */}
                </div>
                {/* end containter */}
            </div>
        </section>
    );

}
export default Clients;
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Blog from "./Components/Blog/Blog";
import HireUs from "./Components/HireUs/HireUs";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <>
    <Router>
      <Switch>
        <Route exact path = "/">
          <Home></Home>
        </Route>
        <Route exact path = "/HireUs">
          <HireUs></HireUs>
        </Route>
        <Route exact path = "/Blog">
          <Blog></Blog>
        </Route>
        <Route exact path = "/Home">
          <Home></Home>
        </Route>
      </Switch>
      <Footer></Footer>
    </Router>
    </>
  );
}

export default App;

import React, { useState } from "react";
import "animate.css";
import'./NavHireUs.css';
const Nav = () => {
  const [navbar, setNavbar] = useState(false);
  const navScrollEffect = () =>{
    if(window.scrollY >= 80){
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  }
  window.addEventListener('scroll', navScrollEffect);

  return (
    <nav className={navbar ? 'navbar hireUsNav scrollNavEffect sticky-top' : 'navbar hireUsNav sticky-top'}>
      <div className="container">
        <a className="navbar-brand" href="/Home">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 37.62 49.4141"><polygon points="33.061 10.497 23.954 3.782 22.654 2.83 20.66 1.364 18.808 0 16.955 1.364 14.961 2.83 13.662 3.782 4.554 10.497 0 13.842 0 19.502 4.554 16.145 13.662 9.442 18.216 6.085 18.808 5.647 19.4 6.085 23.954 9.442 33.061 16.145 37.615 19.502 37.615 13.842 33.061 10.497" fill="#f36"/><polygon points="33.06 24.144 37.62 27.504 37.62 21.844 33.06 18.494 24.31 12.049 24.31 12.044 23.01 11.094 19.75 8.694 19.75 25.304 19.75 25.304 23.95 28.404 33.06 35.104 33.06 40.404 23.95 33.694 22.65 32.744 19.75 30.604 19.75 36.254 23.95 39.354 33.06 46.054 37.62 49.414 37.62 32.804 33.06 29.464 24.31 23.01 24.31 17.709 33.06 24.144" fill="#fff"/><polygon points="13.67 28.404 13.32 28.664 13.32 33.952 4.56 40.404 4.56 18.494 0 21.844 0 49.414 4.56 46.054 7.692 43.751 7.69 43.754 13.32 39.614 13.67 39.354 17.86 36.254 17.87 25.304 13.67 28.404" fill="#f36"/></svg>
        </a>
        <a href="/Home">
          <button type="button" className="backToMainPage">MAIN PAGE</button>
        </a>
      </div>
    </nav>
  );
};

export default Nav;
